import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Et fellesprosjekt`}</h2>
    <p>{`Komponentene og designsystemet generelt trenger jevnlig vedlikehold og videreutvikling, for å holde tritt med løsningene våre og webteknologi for øvrig. I SpareBank 1 Utvikling har vi et eget designsystem-team som skal sørge for at vi jobber målrettet og helhetlig med dette.`}</p>
    <p>{`For at designsystemet skal holde seg oppdatert og dekke alle de viktigste behovene våre er det viktig at teamene bidrar aktivt med å rapportere inn feil, dele ønsker og komme med konkrete løsningsforslag på endringer og nye komponenter.`}</p>
    <h2>{`Design, kode og dokumentasjon må være i synk`}</h2>
    <p>{`For at vi skal kunne samarbeide godt på tvers av design og utvikling er det viktig at designsystemet er oppdatert og synkronisert i både Figma, GitHub og dokumentasjonen på design.sparebank1.no.`}</p>
    <p>{`Når vi endrer på noe eller legger til nye komponenter i designsystemet må vi derfor sørge for at vi gjør endringene alle steder. Det krever at vi jobber tverrfaglig og lager et helhetlig forslag som både inneholder et designforslag i Figma, kodeforslag på GitHub og eventuelt også forslag til dokumentasjon og retningslinjer som skal inn på design.sparebank1.no.`}</p>
    <p>{`Les mer konkret hvordan du går frem for å `}<a parentName="p" {...{
        "href": "/bidra/meld-inn-feil/"
      }}>{`melde inn en feil`}</a>{`, `}<a parentName="p" {...{
        "href": "/bidra/kom-med-onske/"
      }}>{`komme med et ønske`}</a>{` eller `}<a parentName="p" {...{
        "href": "/bidra/kom-med-losningsforslag/"
      }}>{`komme med et løsningsforslag`}</a>{`.`}</p>
    <h2>{`Hva kan designsystem-teamet hjelpe deg med?`}</h2>
    <p>{`Designsystem-teamet er her for å sikre helheten og brukervennligheten i designsystemet og sørge for at vi ivaretar viktige aspekter som merkevare og visuell identitet, universell utforming og god praksis for design og kode.`}</p>
    <p>{`Når du har forslag til endringer eller noe nytt du tenker bør inn i designsystemet, kan designsystem-teamet være en god sparringspartner og gi råd til hvordan du går frem.`}</p>
    <p>{`For spørsmål rundt merkevaren og den visuelle identiteten til SpareBank 1 kan du kontakte Monica Evje og Tora Mellbye fra avdeling Posisjon og Merkevare, eller Espen Voll Nielsen som er fagleder for grafisk design i SpareBank 1 Utvikling.`}</p>
    <h2>{`Code of Conduct`}</h2>
    <p>{`Vi oppfordrer alle til å holde en hyggelig og inkluderende tone. Alle har noe de kan bidra med, og alt er åpent for diskusjon. Mer om dette i vår `}<a parentName="p" {...{
        "href": "https://github.com/SpareBank1/designsystem/blob/develop/CODE_OF_CONDUCT.md"
      }}>{`Code of Conduct`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      